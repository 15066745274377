<template>
  <q-page padding>
    <div
      v-if="stash.trams.length > 0"
      class="row q-col-gutter-sm justify-center"
    >
      <div class="col-sm-12">
        <m-group-title :label="$t('tram.choose.route')" />
      </div>
      <div v-if="!hideNotify" class="col-sm-12" style="padding:16px">
        <q-card class="col-sm-12">
          <q-card-section horizontal>
            <q-card-section class="col-10">
              {{ $t('tram.tickets_unavailable_for_purchase') }}
            </q-card-section>

            <q-card-actions class="col-2" align="right">
              <q-btn color="primary" :label="$t('dismiss_notification')" @click="() => { hideNotify=true }" />
            </q-card-actions>
          </q-card-section>
        </q-card>
      </div>
      <div
        v-for="(tram, i) in stash.trams"
        :key="tram.token"
        class="col-sm-12"
      >
        <tram-card
          :tram="tram"
          highlight="blue"
          @click="setRoute"
        />
        <help-card v-if="i === 2 || i === stash.trams.length - 1" />
      </div>
    </div>

    <div v-else-if="stash.loading" class="loading">
      <q-spinner-dots size="60" />
    </div>

    <m-empty-state v-else icon="tram" is-travel-icon>
      {{ $t('no_trams_found') }}
    </m-empty-state>
  </q-page>
</template>
<script>
import tramCard from './tram-card'
import { mapGetters } from 'vuex'
import { search } from 'api/tram'
import loading from 'utils/loading'
import store from 'store'
import { handleErrors } from 'utils/utils'
import date from 'utils/date-time'
import helpCard from '../../shared/help-card'
import i18n from 'i18n'
import { MGroupTitle, MEmptyState } from 'components/'

export default {
  name: 'OndemandTramRoutes',
  components: {
    tramCard,
    helpCard,
    MGroupTitle,
    MEmptyState
  },
  data () {
    return {
      trams: null,
      hideNotify: false
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    })
  },
  beforeRouteEnter (to, from, next) {
    const stash = store.getters['ondemand/stash']
    const partner = store.getters['partner']
    const { origin, destination, depart } = stash.parameters
    loading.start({
      message: i18n.t('loading.searching.tram'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    const params = {
      origin: origin.place_id || origin.value,
      destination: destination.place_id || destination.value,
      depart: date.toCivilDateTime(depart)
    }

    search(params)
      .then((response) => {
        let data = response.data
        store.dispatch('ondemand/stash', {
          trams: data.outbound,
          selected: {
            route: data.outbound ? data.outbound[0] : null
          }
        })
        next(vm => {
          loading.stop()
        })
      })
      .catch((err) => {
        handleErrors(err)
        next(false)
        loading.stop()
      })
  },
  methods: {
    setRoute (route, data) {
      const selected = this.stash.selected
      selected.route = route
      store.dispatch('ondemand/stash', { selected })
    }
  }
}
</script>
<style lang="stylus" scoped>

.vertical-rule
  max-width 1px !important
  margin-top 27px
  margin-bottom 7px
  background #c6c7c4

.horizontal-rule
  margin-left 8px
  margin-right 8px
  padding-bottom 5px
  border-bottom 1px solid #c6c7c4

.loading
  background rgba(244,244,244, .5)
  position absolute
  top 0
  right 150px
  bottom 0
  left 150px
  svg
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
</style>
