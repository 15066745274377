<template>
  <div class="row">
    <sub class="col-sm-12 location-info">
      <q-chip
        v-for="(line) in journey.line_names"
        :key="line"
        class="float-right"
        square
        :color="lineColor(line)"
        outline
        dense
        :title="$t('tram.line', { num: line })"
      >
        {{ line }}
      </q-chip>
      <span v-html="$t('origin_to_destination', { origin: journey.origin.name, destination: journey.destination.name })" />
      <br>
    </sub>
    <p class="col-12 text-grey-7">
      <span class="leg-date">{{ journey.depart.date | date }}</span>
    </p>

    <h6 class="col-11 times">
      {{ journey.depart.date | time }}
      <q-icon name="arrow_forward" class="arrow" />
      {{ journey.arrive.date | time }}
    </h6>
    <span class="col-11 text-grey-7 leg-info">
      <a>{{ $t('num.changes', { num: journey.number_of_changes }) }}</a>
      , {{ journey.duration.text }}
    </span>
  </div>
</template>

<script>
import date from 'utils/date-time'
export default {
  filters: {
    date: value => date.toHugeDate(value),
    time: value => date.toCivilTime(value)
  },
  props: ['journey'],
  methods: {
    lineColor: value => value.split(' ')[0].toLowerCase() || 'grey-8'
  }
}
</script>

<style lang="stylus" scoped>
.location-info
  margin-bottom 8px
.location-info .destination
  font-size 20px

.leg-name
  text-transform uppercase
  font-weight 700
  font-size 15px

.leg-date
  color black
  font-size 13px

.times
  font-size 20px

.arrow
  vertical-align bottom

.leg-info
  font-size 13px

</style>
