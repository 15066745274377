import date from 'utils/date-time'
const { addToDate } = date

export default function () {
  return {
    traveller: null,
    origin: null,
    destination: null,
    depart: addToDate(date.newDate(), { minutes: 5 })
  }
}
