import http from './http'

export function search (params) {
  return http.get(`/search/tram`, { params })
}

export function summary (token, params) {
  if (params) {
    return http.post(`/search/tram/${token}`, params)
  }

  return http.get(`/search/tram/${token}`)
}
