<template>
  <q-drawer
    v-model="show"
    side="right"
    elevated
  >
    <div class="column items-strech">
      <q-stepper
        ref="stepper"
        v-model="currentStep"
        done-color="primary"
        vertical
        header-nav
      >
        <q-step
          name="ondemand-tram-routes"
          default
          :title="$t('best_route_for_you')"
          :header-nav="navigatable.includes('ondemand-tram-routes')"
          :done="navigatable.includes('ondemand-tram-routes')"
        >
          <q-btn
            v-if="stash.selected.route"
            @click="$router.push({ name: 'ondemand-tram-summary' })"
          >
            {{ $t('continue') }}
          </q-btn>
        </q-step>
        <q-step
          name="ondemand-tram-summary"
          default
          :title="$t('review_and_submit')"
          :header-nav="navigatable.includes('ondemand-tram-summary')"
          :done="navigatable.includes('ondemand-tram-summary')"
        />
        <q-step
          name="ondemand-tram-payment"
          default
          :title="$t('payment')"
          :header-nav="navigatable.includes('ondemand-tram-payment')"
          :done="navigatable.includes('ondemand-tram-payment')"
        />
      </q-stepper>
    </div>
  </q-drawer>
</template>
<script>
// import { editSearch } from 'api/tram'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      show: !this.$q.platform.is.mobile
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    navigatable () {
      let availablePaths = []
      switch (this.$route.name) {
      case 'ondemand-tram-payment':
        availablePaths.push('ondemand-tram-payment')
      case 'ondemand-tram-summary':
        availablePaths.push('ondemand-tram-summary')
      case 'ondemand-tram-routes':
        availablePaths.push('ondemand-tram-routes')
        break
      }
      return availablePaths
    },
    currentStep: {
      get () {
        return this.$route.name
      },
      set (step) {
        this.$router.push({ name: step })
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
  .overview-price
    padding 8px
    .total
      font-size 1em
    .price
      margin 4px 0
      font-size 1.6em
    .people
      font-size 0.8em
  .q-item-sublabel
    text-align center
    margin-bottom 15px
  .times
    padding 30px
    padding-top 0
    justify-content center
    display flex
    button
      &:first-of-type
        margin-right 15px

.time-button
  width 86px
</style>
