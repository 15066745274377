<template>
  <q-page class="page bg-white column items-center no-wrap">
    <div class="code" :class="$store.getters.partner.slug" />
    <div class="container">
      <div class="row">
        <q-card
          square
          class="bg-white"
        >
          <q-card-section :class="`bg-primary text-white text-uppercase`">
            {{ $tc('find.tram') }}
          </q-card-section>
          <q-separator />
          <q-card-section>
            <div class="train-grid">
              <m-location-search
                ref="pickUp"
                :value="parameters.origin"
                :label="$t('going_from')"
                class="origin search-btn"
                icon="fa fa-location"
                no-shadow
                keep-open
                @selected="originSelect"
              />

              <q-btn
                flat
                class="icon swap"
                size="14px"
                icon="fa fa-exchange"
                color="primary"
                @click="swap"
              />

              <m-location-search
                ref="pickUp"
                :value="parameters.destination"
                :label="$t('going_to')"
                class="destination search-btn"
                icon="fa fa-map-marker"
                no-shadow
                keep-open
                @selected="destinationSelect"
              />
            </div>

            <div class="row q-col-gutter-sm date">
              <div class="col-6">
                <m-date-field
                  v-model="parameters.depart"
                  :min="minDepart"
                  :label="$t('date.travel')"
                  :outlined="$q.platform.is.mobile"
                  :rounded="$q.platform.is.mobile"
                />
              </div>
              <div class="col-6">
                <m-time-field
                  v-model="parameters.depart"
                  :min="minDepart"
                  :minute-step="15"
                  :label="$t('time.from')"
                  :outlined="$q.platform.is.mobile"
                  :rounded="$q.platform.is.mobile"
                />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="row">
        <div class="col">
          <m-unified-users v-model="people" />
        </div>
      </div>
      <div class="cta">
        <m-cta-button :label="$tc('find.tram', 2)" :disabled="disabled" @ctaClick="submit" />
      </div>
    </div>
  </q-page>
</template>

<script type="text/javascript">
import 'styles/ondemand.styl'
import authentication from 'mixins/authentication'
import { MLocationSearch, MTimeField, MDateField, MUnifiedUsers, MCtaButton } from 'components/'
import model from './model'
import { query } from 'api/places'
import date from 'utils/date-time'
import searchLocation from 'mixins/searchLocation'
const { addToDate } = date

export default {
  name: 'OndemandBus',
  components: {
    MLocationSearch,
    MUnifiedUsers,
    MTimeField,
    MDateField,
    MCtaButton
  },
  mixins: [authentication, searchLocation],
  data () {
    return {
      parameters: model()
    }
  },
  computed: {
    people: {
      get () {
        return {
          traveller: this.parameters.traveller,
          requester: this.parameters.requester
        }
      },
      set (val) {
        this.parameters.requester = val.requester
        this.parameters.traveller = val.traveller
      }
    },
    disabled () {
      const locationsSet = this.parameters.origin && this.parameters.destination
      return !(locationsSet && this.parameters.traveller && this.parameters.requester)
    },
    minDepart () {
      // Min departure param is (now + 30 minutes) rounded to the next 15 min
      return addToDate(date.newDate(), { minutes: 5 })
    }
  },
  watch: {
    '$route.params.origin': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.origin = val
        }
      }
    },
    '$route.query.origin': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.origin = val
        }
      }
    },
    '$route.query.destination': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.destination = val
        }
      }
    }
  },
  created () {
    this.$store.commit('ondemand/clear')
  },
  activated () {
    this.$store.commit('ondemand/clear')
  },
  methods: {
    originSelect (value) {
      this.parameters.origin = value
    },
    destinationSelect (value) {
      this.parameters.destination = value
    },
    autocomplete (terms) {
      return query(terms)
        .then(response => {
          return response.data.map(location => {
            return {
              label: location.description,
              value: location.place_id,
              icon: 'place'
            }
          })
        })
    },
    submit () {
      this.$store.dispatch('ondemand/stash', { parameters: this.parameters })
      this.$router.push({ name: 'ondemand-tram-routes' })
    },
    swap () {
      [this.parameters.destination, this.parameters.origin] = [
        this.parameters.origin,
        this.parameters.destination
      ]
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
