<template>
  <q-page padding>
    <div
      v-if="summary"
      class="row q-col-gutter-sm"
    >
      <div class="col-sm-12">
        <q-card class="bg-white">
          <q-card-section class="bg-primary">
            {{ $t('booking.summary') }}
          </q-card-section>
          <q-card-section>
            <div
              class="row"
              style="margin-top: 16px"
            >
              <tram-journey
                :journey="summary.journey.outbound"
              />
            </div>
            <q-separator class="hr" />
            <div v-if="summary.total_price.mobilleo_booking_fee" class="row">
              <span class="offset-6 col-4 text-grey-9 text-right">{{ $t('booking.fee') }}</span>
              <span class="col-2 text-grey-9 text-right">{{ summary.total_price.mobilleo_booking_fee.text }}</span>
            </div>
            <div v-if="summary.total_price.total_ticket_cost" class="row">
              <span class="offset-6 col-4 text-grey-9 text-right">{{ $t('net_amount') }}</span>
              <span class="col-2 text-grey-9 text-right">{{ summary.total_price.total_ticket_cost.text }}</span>
            </div>
            <div v-if="summary.total_price.vat" class="row">
              <span class="offset-6 col-4 text-grey-9 text-right">{{ $t('tax.vat') }}</span>
              <span class="col-2 text-grey-9 text-right">{{ summary.total_price.vat.text }}</span>
            </div>
            <q-separator class="hr" />
            <h6
              v-if="summary.total_price.total_cost"
              class="row text-bold"
            >
              <span class="offset-6 col-4 text-grey-9 text-right">{{ $t('total_cost') }}</span>
              <span class="col-2 text-grey-9 text-right">{{ summary.total_price.total_cost.text }}</span>
            </h6>
          </q-card-section>
        </q-card>
      </div>
      <div class="col-sm-12">
        <m-attach-to-booking-request
          :suggested="summary.suggested_journey_name"
          :username="stash.parameters.traveller.value"
          :journey="journey"
          :show-travel-type="travelPolicy && travelPolicy.tram.enabled === true"
          @change="(val) => $store.dispatch('ondemand/journey', val)"
          @reasonChange="(val) => reason_for = val"
          @labelChange="(val) => labels = val"
          @travelTypeChange="(val) => travelType = val"
        />
      </div>
      <payment-buttons
        :total-amount="summary.total_price.total_cost.amount"
        :requester="stash.parameters.requester"
        :traveller="stash.parameters.traveller || stash.parameters.requester"
        :journey="journey"
        :reason-for="reason_for"
        :labels="labels"
        :booking-token="stash.selected.route.token"
        :travel-type="(travelPolicy && travelPolicy.tram.enabled === true) || !organisation ? travelType : 'Private'"
      />
      <m-terms />
    </div>
  </q-page>
</template>
<script>
import { handleErrors } from 'utils/utils'
import { mapGetters } from 'vuex'
import store from 'store'
import { summary } from 'api/tram'
import loading from 'utils/loading'
import paymentButtons from 'pages/payments/payment-method-buttons'
import tramJourney from './tram-journey'
import i18n from 'i18n'
import { MAttachToBookingRequest, MTerms } from 'components/'

export default {
  components: {
    tramJourney,
    paymentButtons,
    MAttachToBookingRequest,
    MTerms
  },
  data () {
    return {
      summary: null,
      reason_for: null,
      labels: null,
      travelType: null
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash',
      journey: 'ondemand/journey',
      partner: 'partner',
      organisation: 'organisation'
    }),
    travelPolicy () {
      if (!this.organisation) return null
      if (this.organisation?.attributes?.travel_policy) {
        return this.organisation.attributes.travel_policy
      }
      return { tram: { enabled: true } }
    }
  },
  beforeRouteEnter (to, from, next) {
    const stash = store.getters['ondemand/stash']
    const partner = store.getters['partner']
    loading.start({
      message: i18n.t('loading.getting.ticket_summary'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })
    summary(stash.selected.route.token)
      .then((response) => {
        next(vm => {
          vm.summary = {
            ...response.data,
            route: stash.selected.route
          }
          loading.stop()
        })
      })
      .catch((err) => {
        handleErrors(err)
        next(false)
      })
  }
}
</script>
<style lang="stylus" scoped>
.layout-padding
  max-width 768px
button
  width 100%
span
  margin-top 16px
.hr
  margin-top 24px
  margin-bottom 10px

.item
  padding-left 0

.delivery-text
  margin-bottom 0

.adults
  padding-bottom 0
  min-height: 0

.adult-text
  font-size: 14px!important
</style>
