<template>
  <q-card
    class="cursor-pointer card"
    :class="classes"
    square
    @click.native="selected"
  >
    <div class="row q-col-gutter-xs">
      <div
        class="col"
        style="padding:16px"
      >
        <div class="row">
          <sup
            class="text-grey-7"
            v-html="$t('origin_to_destination', { origin: tram.origin.name, destination: tram.destination.name })"
          />
        </div>
        <div class="row segment justify-around items-center text-dark">
          <strong class="col-xs-4 depart">{{ tram.depart.date | date }}</strong>
          <div class="col-xs-4">
            <div
              :class="`text-${highlight}-8`"
              class="line"
            />
          </div>
          <strong class="col-xs-4 text-right arrive">{{ tram.arrive.date | date }}</strong>
        </div>
      </div>
    </div>
    <q-separator />
    <div class="row">
      <q-list class="col">
        <q-expansion-item
          ref="expansion"
          group="tram-card-expansion-item"
          :label="$t('timeline')"
          :header-class="`text-grey-8 bg-white`"
          class="bg-white full-height"
          @click.stop
        >
          <q-timeline layout="loose" color="grey" class="q-px-sm">
            <q-timeline-entry
              v-for="(leg, index) in tram.segments"
              :key="index"
              :subtitle="leg.departure_time"
              :icon="legStyle(leg.type).icon"
              :side="leg.type === 'tram' ? 'right' : 'left'"
              :color="legStyle(leg.type).color"
              class="text-dark"
            >
              <template v-slot:title>
                <sup class="text-weight-regular">
                  <strong>{{ leg.line }}</strong>
                  <br>
                  {{ leg.from_point }}
                </sup>
              </template>
              <div class="text-caption text-faded">
                {{ $t('going_to_place', { place: leg.to_point }) }}
              </div>
              <q-tooltip>{{ legStyle(leg.type).label }}</q-tooltip>
            </q-timeline-entry>
            <q-timeline-entry
              class="text-dark"
              :subtitle="tram.arrive.date | date"
            >
              <template v-slot:title>
                <sup class="text-dark text-weight-regular">
                  {{ $t('arrive_at_time', { time: date(tram.arrive.date) }) }}
                </sup>
              </template>
            </q-timeline-entry>
          </q-timeline>
        </q-expansion-item>
      </q-list>
    </div>
  </q-card>
</template>
<script>
import date from 'utils/date-time'
import { mapGetters } from 'vuex'

export default {
  name: 'TramCard',
  filters: {
    date: value => date.toCivilTime(value)
  },
  props: {
    tram: null,
    highlight: {
      type: String,
      default: 'blue'
    }
  },
  data () {
    return {
      tab: 'timeline'
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    tramLegs () {
      return this.tram.segments.filter((leg) => {
        return leg.type === 'tram'
      })
    },
    isSelected () {
      return this.stash.selected.route === this.tram || this.stash.selected.route === this.tram
    },
    classes () {
      return [this.isSelected ? `selected bg-${this.highlight}-1 text-${this.highlight}` : '']
    }
  },
  methods: {
    capitalise (string) {
      return string[0].toUpperCase() + string.toLowerCase().slice(1)
    },
    toHoursMins: (string) => string.replace(/ hours/, 'hrs').replace(/ minutes/, 'mins'),
    legStyle (type) {
      switch (type) {
      case 'tram':
        return {
          icon: 'tram',
          color: `${this.highlight}-8`,
          label: this.$tc('content_type.tram')
        }
      case 'taxi':
        return {
          icon: 'local_taxi',
          color: 'black',
          label: this.$tc('content_type.taxi')
        }
      case 'foot':
        return {
          icon: 'directions_walk',
          color: 'green-8',
          label: this.$t('content_type.walking')
        }
      default:
        return {
          icon: 'far fa-question-circle',
          color: 'grey',
          label: `${this.$t('unknown_transport_type')}: ${type}`
        }
      }
    },
    selected () {
      this.$emit('click', this.tram)
      this.$refs.expansion.show()
    },
    date (dateValue) {
      return dateValue ? date.toCivilTime(dateValue) : null
    }
  }
}
</script>
<style lang="stylus" scoped>
.q-card
  min-height 120px
  position relative
  background-color #fff

.journey-info:hover
  text-decoration underline

.modal-body
  min-width 500px

.segment
  font-size 1.4em
  margin-top 8px
.line
  position relative
  margin: 0 8px
  height: 1px
  background-color currentColor
  &:before,
  &:after
    content ""
    position absolute
    width 10px
    height 10px
    background currentColor
    border-radius 100%
    top -5px
  &:after
    right 0

.journey-leg
  position relative
  border-top 1px dashed lightgray
  padding-bottom 15px
  padding-top 15px

  h6
    margin-top 0

.journey-leg:first-child
  border-top 0

.point, .way
  background #1976d2

.point
  width 20px
  height 20px
  border-radius 100%
  top -5px

.way
  height 60px
  width 2px
  margin-left 9px

.to
  position absolute
  bottom 5px

sub
  display block

.spacer
  height 62px
</style>
